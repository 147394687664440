<template>
  <div class="page">
    <div class="page_frame" v-if="step !== 2">
      <div class="step1"></div>
      <div class="content_us_table">
        <select
          :class="[alert === 1 ? 'input_frame1 alert' : 'input_frame1']"
          v-model="product"
        >
          <option value="" disabled selected hidden>
            请选择你感兴趣的产品名称（必填）
          </option>
          <option v-for="(item, index) in list" :key="index" :value="item.id">
            {{ item.model }}
          </option>
        </select>
        <input
          :class="[alert === 2 ? 'input_frame2 alert' : 'input_frame2']"
          type="text"
          placeholder="请输入姓名（必填）"
          v-model="name"
        />
        <input
          :class="[alert === 3 ? 'input_frame2 alert' : 'input_frame2']"
          type="text"
          placeholder="请输入公司（必填）"
          v-model="company"
        />
        <input
          :class="[alert === 4 ? 'input_frame2 alert' : 'input_frame2']"
          type="tel"
          maxlength="11"
          placeholder="请输入电话（必填）"
          v-model="tel"
        />
        <input
          :class="[alert === 5 ? 'input_frame2 alert' : 'input_frame2']"
          type="email"
          placeholder="请输入邮箱（选填）"
          v-model="email"
        />
        <textarea
          class="input_frame3"
          placeholder="在线留言（选填）"
          v-model="remark"
        ></textarea>
        <div class="submit_notes">
          *通过提交，所提供的数据将根据隐私政策执行您的要求
        </div>
        <div class="submit_btn" @click="submit_form()">提交留言</div>
      </div>
    </div>
    <div class="page_frame" v-if="step === 2">
      <div class="step2"></div>
      <div class="content_us_table">
        <div
          :class="[
            selected === index ? 'download_select alert' : 'download_select',
          ]"
          v-for="(item, index) in list"
          :key="index"
          @click="select(index)"
        >
          {{ item.model }}
        </div>
        <div class="submit_btn2" @click="submit_step2()">页面下载</div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import { useRoute } from "vue-router";
import Footer from "../components/Footer";
import { GET_COMMENTS, GET_PRODUCTS } from "../http/api";
import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({
  position: "top",
  duration: 2500,
});

export default {
  data() {
    return {
      step: 1,
      list: [],
      product: "",
      name: "",
      company: "",
      tel: "",
      email: "",
      remark: "",
      alert: null,
      selected: null,
    };
  },
  setup() {
    const route = useRoute();
    const getParams = () => {
      return route.params;
    };
    return {
      getParams,
    };
  },
  name: "Form",
  components: {
    Footer,
  },
  created: function () {
    let language = localStorage.getItem("language");
    GET_PRODUCTS(`page_size=100&language=${language}`).then((res) => {
      let list = [];
      for (let i = 0; i < res.results.length; i++) {
        let temp = res.results[i];
        temp.description = res.results[i].description.replace(
          /\r\n/g,
          "<br />"
        );
        let array = res.results[i].features.split(/\r\n/);
        temp.features = array.filter((item) => item.trim() !== "");
        list.push(temp);
      }
      this.list = list;
    });
  },
  methods: {
    submit_form() {
      if (this.product === "") {
        this.alert = 1;
        return;
      }
      if (this.name.trim() === "") {
        this.alert = 2;
        return;
      }
      if (this.company.trim() === "") {
        this.alert = 3;
        return;
      }
      const phone = /^1[3|4|5|6|7|8|9]\d{9}$/;
      if (!phone.test(this.tel)) {
        this.alert = 4;
        return;
      }
      const email = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (this.email !== "") {
        if (!email.test(this.email)) {
          this.alert = 5;
          return;
        }
      }
      this.step = 2;
    },
    select(index) {
      this.selected = index;
    },
    downloadFile(data, fileName) {
      // 下载pdf文件
      const a = document.createElement("a");
      // 这里是将url转成blob地址，
      fetch(data)
        .then((res) => res.blob())
        .then((blob) => {
          // 将链接地址字符内容转变成blob地址
          let url = URL.createObjectURL(blob);
          a.href = url;
          a.setAttribute("download", fileName + ".pdf");
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(url);
          document.body.removeChild(a);
          window.history.go(-1);
        });
    },
    submit_step2() {
      if (this.selected !== null) {
        GET_COMMENTS({
          product: this.product,
          name: this.name,
          company: this.company,
          phone: this.tel,
          email: this.email,
          comment: this.remark,
        }).then(() => {
          toaster.success(`提交成功！`);
          let index = this.selected;
          let temp = this.list[index];
          let submitInfo = localStorage.getItem("submitInfo")
            ? JSON.parse(localStorage.getItem("submitInfo"))
            : [];
          if (!submitInfo.includes(this.product)) {
            submitInfo.push(this.product);
            localStorage.setItem("submitInfo", JSON.stringify(submitInfo));
          }
          this.downloadFile(temp.file, temp.model);
        });
      } else {
        toaster.error(`请选择要下载的产品型号`);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  width: 100%;
  height: calc(100vh - 34px);
  min-height: 700px;
  background: #3b4455;
  .page_frame {
    position: relative;
    width: 1000px;
    height: 699px;
    padding-top: 1px;
    margin: 0 auto;
    .step1 {
      width: 200px;
      height: 68px;
      margin-top: 110px;
      margin-left: 96px;
      background: url("../assets/download_step1.png") center center no-repeat;
      background-size: contain;
    }
    .step2 {
      width: 200px;
      height: 68px;
      margin-top: 110px;
      margin-left: 96px;
      background: url("../assets/download_step2.png") center center no-repeat;
      background-size: contain;
    }
    .content_us_table {
      position: relative;
      width: 712px;
      height: 420px;
      margin-top: 50px;
      margin-left: 96px;
      text-align: left;
      .input_frame1 {
        width: 695px;
        height: 50px;
        font-size: 17px;
        color: #b1b3b7;
        line-height: 50px;
        padding-left: 17px;
        padding-right: 34px;
        border-radius: 8px;
        margin-bottom: 12px;
        background: url("../assets/contact_select.png") #20242f center right
          17px no-repeat;
        background-size: 6px auto;
        &.alert {
          border: 1px solid #b10011;
        }
      }
      .input_frame2 {
        width: 324px;
        height: 50px;
        font-size: 17px;
        color: #b1b3b7;
        line-height: 50px;
        padding-left: 17px;
        border-radius: 8px;
        margin-bottom: 12px;
        background: #20242f;
        display: block;
        &.alert {
          border: 1px solid #b10011;
        }
      }
      .input_frame2::-webkit-input-placeholder {
        /*WebKit browsers*/
        color: #b1b3b7;
      }
      .input_frame2::-moz-input-placeholder {
        /*Mozilla Firefox*/
        color: #b1b3b7;
      }
      .input_frame2::-ms-input-placeholder {
        /*Internet Explorer*/
        color: #b1b3b7;
      }
      .input_frame2::placeholder {
        color: #b1b3b7;
      }
      .input_frame3 {
        position: absolute;
        top: 62px;
        right: 17px;
        width: 320px;
        height: 236px;
        font-size: 17px;
        color: #b1b3b7;
        line-height: 50px;
        padding-left: 17px;
        border-radius: 8px;
        background: #20242f;
      }
      .input_frame3::-webkit-input-placeholder {
        /*WebKit browsers*/
        color: #b1b3b7;
      }
      .input_frame3::-moz-input-placeholder {
        /*Mozilla Firefox*/
        color: #b1b3b7;
      }
      .input_frame3::-ms-input-placeholder {
        /*Internet Explorer*/
        color: #b1b3b7;
      }
      .input_frame3::placeholder {
        color: #b1b3b7;
      }
      .submit_notes {
        position: absolute;
        bottom: 25px;
        left: 0;
        font-size: 15px;
        color: #b1b3b7;
        line-height: 42px;
      }
      .submit_btn {
        position: absolute;
        bottom: 25px;
        right: 0;
        width: 129px;
        height: 42px;
        border-radius: 8px;
        background: #b10011;
        font-size: 17px;
        color: #ffffff;
        line-height: 42px;
        letter-spacing: 2px;
        text-align: center;
      }
      .download_select {
        width: 600px;
        height: 50px;
        font-size: 17px;
        color: #b1b3b7;
        line-height: 50px;
        padding-left: 17px;
        border-radius: 8px;
        margin-bottom: 12px;
        background: #20242f;
        &.alert {
          border: 1px solid #b10011;
        }
      }
      .submit_btn2 {
        width: 129px;
        height: 42px;
        margin-top: 100px;
        margin-right: 30px;
        border-radius: 8px;
        background: #b10011;
        font-size: 17px;
        color: #ffffff;
        line-height: 42px;
        letter-spacing: 2px;
        text-align: center;
        float: right;
      }
    }
  }
}
</style>
