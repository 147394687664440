<template>
  <div id="foot">
    <div class="foot_line">
      <span style="float: left">LUXET©2024</span>
      Copy©Luxet All Rights Reserved.
      <span class="go_top" @click="go_top()">Back To Top</span>
    </div>
  </div>
</template>

<script>
import VueScrollTo from "vue-scrollto";
import { useRouter } from "vue-router/dist/vue-router";

export default {
  name: "Footer",
  setup() {
    const router = useRouter();
    const jump_page = (page_name, value) => {
      router.push({
        name: page_name,
        params: {
          value: JSON.stringify(value),
        },
      });
    };
    return {
      jump_page,
    };
  },
  methods: {
    go_top() {
      VueScrollTo.scrollTo(`body`);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#foot {
  position: relative;
  width: 100%;
  height: 34px;
  background: #3b4455;
  .foot_line {
    width: 840px;
    height: 32px;
    margin: 0 auto;
    font-size: 12px;
    color: #b1b3b7;
    line-height: 32px;
    border-top: 2px rgba(255, 255, 255, 0.1) solid;
    text-align: center;
    .go_top {
      padding-right: 15px;
      background: url("../assets/contact_back.png") center right no-repeat;
      background-size: auto 10px;
      float: right;
    }
  }
}
</style>
